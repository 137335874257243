import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import HomeIcon from '@material-ui/icons/Home';
import HotelIcon from '@material-ui/icons/Hotel';
import ImageIcon from '@material-ui/icons/Image';
import HistoryIcon from '@material-ui/icons/History';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import LockIcon from '@material-ui/icons/Lock';
import MapIcon from '@material-ui/icons/Map';


import { Link } from 'react-router-dom'
import '../index.css';


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    color: "#efefef",
    background: "#434343",
  },
  appBar: {
    transition: "transform 600ms linear",
  	willChange: "transform",
    background: "#434343",
    color: "#efefef",
  },
  appBarShift: {
    //width: `calc(100% - ${drawerWidth}px)`,
    paddingLeft: drawerWidth,
    transition: "transform 600ms linear",
  	willChange: "transform",
  },
  menuButton: {
    margin: "0 0.5em 0 0.5em",
    color: "#efefef",
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    color: "#efefef",
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#434343",
    color: "#efefef",
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    background: "#434343",
    color: "#efefef",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: "transform 600ms linear",
  	willChange: "transform",
    paddingLeft: -drawerWidth,
  },
  contentShift: {
    transition: "transform 600ms linear",
  	willChange: "transform",
    paddingLeft: 0,
  },
  light_text: {
    fill: "#efefef",
  },
  navigation_menu_text: {
    width: "100%",
    textAlign: "center",
    paddingLeft: "2em",
    cursor: "pointer",

  },
  anchor: {
    color: "#efefef",
    textDecoration: "none"
  },
  bar_container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  active_class: {
    background: "#333333",
  },
  mouse_pointer: {
    cursor: "pointer",
  },
}));

export default function Navigation() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function get_active_page_name(){

    var page_name = window.location.href.split("/")[window.location.href.split("/").length - 1];
    if (page_name.length > 0){
        page_name = page_name.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }else{
      page_name = "Home";
    }
    return page_name;
  }

  function get_classname_for_nav_option(page_id){
    var classname = "";
    var page_name = window.location.href.split("/")[window.location.href.split("/").length - 1];
    if(page_id === page_name){
      classname = classes.active_class;
    }
    if(page_name === "" && page_id === "home"){
      classname = classes.active_class;
    }
    return classname;
  }

  const pages = [
    {
      "id": "home",
      "url": "/",
      "icon": <HomeIcon className={classes.light_text}/>,
      "text": "Home"
    },
    {
      "id": "hotel",
      "url": "/hotel",
      "icon": <HistoryIcon className={classes.light_text}/>,
      "text": "Hotel & History"
    },
    {
      "id": "restaurant",
      "url": "/restaurant",
      "icon": <RestaurantIcon className={classes.light_text}/>,
      "text": "Restaurant"
    },
    {
      "id": "accommodation",
      "url": "/accommodation",
      "icon": <HotelIcon className={classes.light_text}/>,
      "text": "Accommodation"
    },
    {
      "id": "activities",
      "url": "/activities",
      "icon": <LocalActivityIcon className={classes.light_text}/>,
      "text": "Nearby Activities"
    },
    {
      "id": "gallery",
      "url": "/gallery",
      "icon": <ImageIcon className={classes.light_text}/>,
      "text": "Image Gallery"
    },
    {
      "id": "contact",
      "url": "/contact",
      "icon": <MailIcon className={classes.light_text}/>,
      "text": "Contact"
    },
    {
      "id": "policy",
      "url": "/policy",
      "icon": <LockIcon className={classes.light_text}/>,
      "text": "Privacy Policy"
    },
    {
      "id": "sitemap",
      "url": "/sitemap",
      "icon": <MapIcon className={classes.light_text}/>,
      "text": "Site Map"
    },
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar >
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.bar_container}>
            <Typography onClick={handleDrawerOpen} className={classes.mouse_pointer} variant="h6" noWrap>
              Gun Lodge Hotel & Cromal Hill Restaurant
            </Typography>
            <Typography variant="h6" noWrap>
              <strong>{get_active_page_name()}</strong>
            </Typography>
          </div>
        </Toolbar>
      </AppBar>


      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h6" className={classes.navigation_menu_text}  onClick={handleDrawerClose} noWrap>
            Menu
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon className={classes.light_text}  /> : <ChevronRightIcon className={classes.light_text}  />}
          </IconButton>
        </div>

        <Divider />

        <List>
          {pages.map((page) => {
            return (
              <Link onClick={handleDrawerClose} className={classes.anchor} to={page.url} key={page.id}>
                <ListItem className={get_classname_for_nav_option(page.id)} button>
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText primary={page.text} />
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
      </main>
    </div>
  );
}
