import React from 'react'


export const AMPMSelector = (props) => {

    return (
        <select
            name={props.name}
            value={props.value}
            onChange={e => props.setter(e.target.value)}
        >
            <option value="AM">AM</option>
            <option value="PM">PM</option>
        </select>
    )
}