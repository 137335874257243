import React from 'react'

//
//  :code:
import {apiUploadFileRequest} from '../../js/utils'

export const Menus = (props) => {

    //
    //  :events:
    //  Functions to handle events.
    const onClickUploadMenu = async (event) => {
        event.preventDefault()
        const file = document.getElementById("menuFile").files[0]
        const response = await apiUploadFileRequest("/menus", file, document.getElementById("menuType").value)
        console.log(response)
    }

    return (
        <div className={props.className}>
            <div>
                <h1>Menus Upload</h1>
                <p>The form below allows for upload of a menu, note the "type" must be selected.</p>

                <p>Note, when uploading a menu of a type that already exists, it will be replaced.</p>

                <form>
                    <div>
                        <label>Type</label>
                        <select name="type" id="menuType">
                            <option value="-1" defaultValue>Select an option</option>
                            <option value="restaurant">Restaurant Menu</option>
                            <option value="drinks">Drinks Menu</option>
                            <option value="whisky">Whisky Menu</option>
                        </select>
                    </div>

                    <div>
                        <label>File</label>
                        <input
                            id="menuFile"
                            type="file" 
                            name="fileFromForm" 
                        />
                    </div>
                    
                    
                    <input onClick={onClickUploadMenu} type='submit' value='Upload' />
                </form>
            </div>

        </div>
    )
}