import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
// https://github.com/FortAwesome/react-fontawesome/blob/development/UPGRADING.md
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faTwitterSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';

import GoogleMapReact from 'google-map-react';

import {OpeningTimesView} from '../components/OpeningTimesView'


const AnyReactComponent = ({ text }) => <FontAwesomeIcon size="lg" icon={faMapMarker} fixedWidth />;
const useStyles = makeStyles(theme => ({
  outer_container: {
    marginTop: "2em",
    background: "rgba(0,0,0,0.75)",
  },
  container: {
    width: "100%",
    maxWidth: "1100px",
    margin: "0 auto 0 auto",
  },
  root: {
    flexGrow: 1,
  },
  full_width: {
    width: "100%",
    textAlign: "center",
  },
  text_center: {
    textAlign: "center",
  },
  uppercase_heading: {
    textTransform: "uppercase",
    borderBottom: "1px solid #cccccc",
    textAlign: "center",
    color: "#cfcfcf",
    width: "200px",
    margin: "0 auto 1em auto",
  },
  grid_item: {
    textAlign: "center",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  grid_item_horizontal: {
    textAlign: "center",
    width: "100%",
  },
  grid_row_information: {
    padding: "3em 0 1em 0",
  },
  grid_row_social_media: {
    background: "#333333",
    color: "#cfcfcf",
    fontSize: "2.5em",
    padding: "0.15em",
  },
  grid_row_final: {
    background: "#303030",
    color: "#bdbdbd",
  },
  flex_row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  flex_row_footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "0.25rem 0.5rem 0.25rem 0.5rem"
  },
  left_right_padding: {
    padding: "0 0.25em 0 0.25em",
  },
  external_map: {
    width: "100%",
    margin: "0 auto 1em",
    height: "320px",

  },
  booking_com_logo: {
    maxWidth: "240px",
  },
  clean_ul: {
    listStyleType: "none",
    margin: "0",
    padding: 0,
    color: "#cfcfcf",
  },
  anchor: {
    color: "#cfcfcf",
    textDecoration: "none",
  },
  contact_icon: {
    fontSize: "3em",
  }
}));

export default function Footer() {
  const defaultProps = {
    center: {
      lat: 57.5719,
      lng: -4.039151
    },
    zoom: 11
  };
  const apiKey = "AIzaSyCiZFG7m5uFQ1-kGYoQNI0zhHPxbDtiXVM"
  const classes = useStyles();

  return (
    <div className={classes.outer_container}>
      <div className={classes.container}>
        <div className={classes.root}>
          <Grid container className={classes.grid_row_information}>
            <Grid className={classes.grid_item} item md={4}>
              <Typography className={classes.uppercase_heading} variant="h6">
                Contact Us
              </Typography>
              <ul className={classes.clean_ul}>
                <li>
                  <a rel="noopener noreferrer" target="_blank" className={classes.anchor} href="https://www.google.com/maps/dir//The+Gun+Lodge+Hotel+%26+Cromal+Restaurant,+The+Gun+Lodge+Hotel,+High+St,+Ardersier,+Inverness+IV2+7QB,+UK/@47.7936934,-11.4016408,5z/data=!4m8!4m7!1m0!1m5!1m1!1s0x488f789901dcf8ad:0x2653e1173d50309c!2m2!1d-4.039151!2d57.5719">
                  <FontAwesomeIcon className={classes.contact_icon} icon={faMapMarker} fixedWidth /> Get directions to us</a>
                </li>
                <br/>
                <li>The Gun Lodge Hotel</li>
                <li>High Street</li>
                <li>Ardersier</li>
                <li>IV2 7QB</li>
                <li><a className={classes.anchor} href="tel:+441667462734">+44 01667 462 734</a></li>
                <li><a className={classes.anchor} href="mailto:info@gunlodgehotel.co.uk?subject=Website+Enquiry">info@gunlodgehotel.co.uk</a></li>
              </ul>
              
              <OpeningTimesView environment={process.env.REACT_APP_ENVIRONMENT} />

              <br/>
            </Grid>
            <Grid className={classes.grid_item} item md={4}>
              <Typography className={classes.uppercase_heading} variant="h6">
                Visit Us
              </Typography>

              <div className={classes.external_map}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: apiKey }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                >
                  <AnyReactComponent
                    lat={57.5719}
                    lng={-4.039151}
                    text="Gun Lodge Hotel"
                  />
                </GoogleMapReact>
              </div>

            </Grid>
            <Grid className={classes.grid_item} item md={4}>
              <Typography className={classes.uppercase_heading} variant="h6">
                Book with us
              </Typography>
              <ul className={classes.clean_ul}>
                <Link className={classes.anchor} to="/contact"><FontAwesomeIcon className={classes.contact_icon} icon={faEnvelope} fixedWidth /> <li>Contact Us</li></Link>
                <br/>
                <li>
                  <a rel="noopener noreferrer" target="_blank" href="https://www.booking.com/hotel/gb/the-gun-lodge.en-gb.html">
                    <img alt="booking.com" className={classes.booking_com_logo} src={process.env.PUBLIC_URL + "/img/booking_external.png"} />
                  </a>
                </li>
                <br/>
                <li>
                  <a rel="noopener noreferrer" target="_blank" href="https://via.eviivo.com/en-en/Google/gunlodgeiv2">
                    <img alt="eviivo.com" className={classes.booking_com_logo} src={process.env.PUBLIC_URL + "/img/booking_external_two.png"} />
                  </a>
                </li>
              </ul>
              <br/>
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid container className={classes.grid_row_social_media} spacing={0}>
        <Grid className={classes.grid_item_horizontal} item md={12}>
          <a rel="noopener noreferrer" target="_blank" className={classes.anchor} href="https://www.facebook.com/GunLodgeHotel/"><FontAwesomeIcon icon={faFacebookSquare} fixedWidth /></a>
          <a rel="noopener noreferrer" target="_blank" className={classes.anchor} href="https://twitter.com/gunlodge"><FontAwesomeIcon icon={faTwitterSquare} fixedWidth /></a>
          <a rel="noopener noreferrer" target="_blank" className={classes.anchor} href="https://www.instagram.com/gunlodgehotel/"><FontAwesomeIcon icon={faInstagram} fixedWidth /></a>
        </Grid>
      </Grid>

      <Grid container className={classes.grid_row_final} spacing={0}>
        <Grid className={classes.flex_row_footer} item md={12}>
        <span className={classes.left_right_padding} >Copyright © Gun Lodge Hotel 2021</span>
        <Link className={classes.anchor} to="/policy">Privacy Policy</Link>
        </Grid>
      </Grid>
    </div>
  );
}
