import React from 'react'


export const TimeSelector = (props) => {

    const mode = props.mode || "minutes"
    const renderAsTimeString = (value) => {
        if(value < 10){
            return `0${value}`
        }
        return `${value}`
    }

    const renderOpeningTimesOptions = (mode) => {
        let options = []
        if(mode === "minutes"){
            for(var i = 0; i < 12; i++){
                options.push(
                    <option key={`${i}-minutes`} value={renderAsTimeString(i*5)}>{renderAsTimeString(i*5)}</option>
                )
            }
        }
        if(mode === "hours"){
            for(var j = 1; j < 13; j++){
                options.push(
                    <option key={`${j}-hours`} value={renderAsTimeString(j)}>{renderAsTimeString(j)}</option>
                )
            }
        }
        return options        
    }

    return (
        <select
            name={props.name}
            value={props.value}
            onChange={e => props.setter(e.target.value)}
        >
            {renderOpeningTimesOptions(mode)}
        </select>
    )
}