import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import record_page_view from '../routing/analytics';

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "0",
    maxWidth: "1600px",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  uppercase_heading: {
    textTransform: "uppercase",
    borderBottom: "1px solid #cccccc",
    marginBottom: "1em",
  },
  description_text: {
    marginBottom: "1em",
  },
  full_width: {
    width: "100%",
    maxWidth: "100%",
  },
  outer_container: {
    width: "100%",
    maxWidth: "100%",
  },
  image_header: {
    margin: 0,
  },
  clean_ul: {
    listStyleType: "none",
    margin: "0",
    padding: 0,
  },
  carousel_img: {
    height: "90vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  car: {
    margin: "0 0"
  }
}));

export default function Gallery() {
  const classes = useStyles();
  const images = [];
  const total_gallery_images = 15;
  for(var i = 0; i < total_gallery_images; i++){
    images[i] = {
      "id": i,
      "alt": "Image " + i,
      "link": process.env.PUBLIC_URL + "/img/gallery/gallery-" + (i + 1) + ".jpg"
    }
  }

  return (
    <div className={classes.outer_container}>
      {record_page_view()}
      <div className={classes.container}>
        <div className={classes.root}>
          <Carousel showStatus={false} className={classes.car} >
            {images.map((image) => {
              return(
                <div className={classes.carousel_img}  key={image.id}>
                    <img  alt={image.alt} src={image.link} />
                </div>
              )
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
