export const API_BASE_URL = process.env.REACT_APP_ENVIRONMENT ?  "https://swdlnkpf76.execute-api.eu-west-1.amazonaws.com/dev" : ""
export const DEV_API_BASE_URL = "http://localhost:3001"


export const weAreInTheCloud = () => {
    return window.location.href.indexOf("amazonaws") > -1
}

export const readEnvironment = () => {
    return `dev`
}

export const pathBuilder = (url) => {
    if(window.location.href.indexOf("localhost") > -1){
        return `${DEV_API_BASE_URL}${url}`
    }
    return `${API_BASE_URL}${url}`
}

export const urlBuilder = (path) => {
    if(weAreInTheCloud()){
        return `${readEnvironment()}/${path}`
    }
    return `${path}`
}

export const renderRequestHeaders = () => {
    const jwt = localStorage.getItem("jwt")
    let headers = {
        "Content-Type": "application/json"
    }
    if(jwt){
        headers["Authorization"] = `Bearer ${jwt}`
    }
    return headers
}

export const apiGetRequest = (path) => {
    return new Promise(async (resolve, reject) => {
        const url = pathBuilder(urlBuilder(path))
        try{
            const response = await fetch(url, {
                headers: renderRequestHeaders()
            })
            const responseData = await response.json() || null
            resolve([response.status, responseData])
        }catch(err){
            console.log(err, err.stack)
            reject(err, err.stack)
        }        
    })
}

export const apiUploadFileRequest = (path, file, type) => {
    return new Promise(async (resolve, reject) => {
        const url = pathBuilder(urlBuilder(path))
        let headers = renderRequestHeaders()
        //headers["Content-Type"] = file.type
        delete headers["Content-Type"]
        const formData = new FormData()
        formData.append('fileFromForm', file)
        formData.append("type", type)
        try{
            const response = await fetch(url, {
                method: "POST",
                headers: headers,
                body: formData
            })
            const responseData = await response.json() || null
            resolve([response.status, responseData])
        }catch(err){
            console.log(err, err.stack)
            reject(err, err.stack)
        }     
    })
}

export const apiPatchRequest = (path, data) => {
    return new Promise(async (resolve, reject) => {
        const url = pathBuilder(urlBuilder(path))
        try{
            const response = await fetch(url, {
                method: "PATCH",
                headers: renderRequestHeaders(),
                body: JSON.stringify(data)
            })
            const responseData = await response.json() || null
            resolve([response.status, responseData])
        }catch(err){
            console.log(err, err.stack)
            reject(err, err.stack)
        }     
    })
}

export const apiPostRequest = (path, data) => {
    return new Promise(async (resolve, reject) => {
        const url = pathBuilder(urlBuilder(path))
        try{
            const response = await fetch(url, {
                method: "POST",
                headers: renderRequestHeaders(),
                body: JSON.stringify(data)
            })
            const responseData = await response.json() || null
            resolve([response.status, responseData])
        }catch(err){
            console.log(err, err.stack)
            reject(err, err.stack)
        }     
    })
}