import React, {useState, useEffect} from 'react'

import styles from './BannerView.module.css'
import {apiGetRequest} from '../js/utils'

/*
const useStyles = makeStyles(theme => ({

}));
*/

export const BannerView = (props) => {
    //const classes = useStyles()
    //
    //  :props:
    const environment = props.environment

    //
    //  :statics:

    //
    //  :state:
    const [isRequestingBanner, setIsRequestingBanner] = useState(false)
    const [bannerResponse, setBannerResponse] = useState(null)
    const [bannerResponseError, setBannerResponseError] = useState(null)

    //
    //  :onload:
    //  Functions that are called when we load the page.
    useEffect(() => {
        async function fetchData() {
            setIsRequestingBanner(true)
            const data = await apiGetRequest("/banner")
            console.log(data)
            if(!data){
                setBannerResponseError({})
                setBannerResponse(null)
            }else{
                setBannerResponseError(null)
                setBannerResponse(data[1])
            }
            setIsRequestingBanner(false)
        }
        if(!bannerResponse && !isRequestingBanner && !bannerResponseError){
            fetchData();
        }
      }, [isRequestingBanner, bannerResponse, bannerResponseError]);


    return (
        <>
            {bannerResponse && (bannerResponse.enabled || props.force === "true") && (
                <div className={styles.bannerContainer}>
                    <h2>{bannerResponse.title}</h2>
                    <span>{bannerResponse.subtitle}</span>
                </div>
            )}
        </>
    )
}
