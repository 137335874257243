import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './index.css';
import Home from './pages/home';
import Restaurant from './pages/restaurant';
import Accommodation from './pages/accommodation';
import Gallery from './pages/gallery';
import Activities from './pages/activities';
import Contact from './pages/contact';
import Hotel from './pages/hotel';
import Policy from './pages/policy';
import Sitemap from './pages/sitemap';
import {Admin} from './pages/admin'


import Navigation from './shared/nav';
import Footer from './shared/footer';

import ScrollToTop from './routing/scroll_to_top';
import NotFound from './routing/404'

import * as serviceWorker from './serviceWorker';
import { BannerView } from './components/BannerView';




const routing = (
<div>
  <Router>
    <ScrollToTop>
      <div>
        <Navigation/>        
        
        <BannerView environment={process.env.REACT_APP_ENVIRONMENT} />

        <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/hotel" component={Hotel} />
        <Route path="/restaurant" component={Restaurant} />
        <Route path="/accommodation" component={Accommodation} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/activities" component={Activities} />
        <Route path="/contact" component={Contact} />
        <Route path="/policy" component={Policy} />
        <Route path="/sitemap" component={Sitemap} />
        <Route path="/admin" component={Admin} />
        <Route component={NotFound} />
        </Switch>
      </div>

      <Footer/>

    </ScrollToTop>
  </Router>
</div>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
