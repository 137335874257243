import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import record_page_view from '../routing/analytics';

import {MenuButton} from '../components/MenuButton'

const background_image_path = `url(` + process.env.PUBLIC_URL + `/img/banner-restaurant.jpg) center center`;

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "3em 10% 0 10%",
    maxWidth: "1366px",
    margin: "0 auto 2em auto",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  uppercase_heading: {
    textTransform: "uppercase",
    borderBottom: "1px solid #cccccc",
    marginBottom: "1em",
  },
  description_text: {
    marginBottom: "1em",
  },
  full_width: {
    width: "100%",
    maxWidth: "100%",
  },
  outer_container: {
    width: "100%",
    maxWidth: "100%",
  },
  image_header: {
    margin: 0,
    height: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: background_image_path,
  },
  clean_ul: {
    listStyleType: "none",
    margin: "0",
    padding: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  flex_center: {
    display: "flex",
    justifyContent: "center",
  },
  one_rem_margin: {
    margin: "0.5rem"
  }
}));

export default function Activities() {
  const classes = useStyles();

  return (
    <div className={classes.outer_container}>
      {record_page_view()}
      <div className={classes.image_header}>
      </div>

      <div className={classes.container}>
        <div className={classes.root}>

              <div key="info">
                <Grid container spacing={0}>
                  <Grid item sm={6}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.uppercase_heading} variant="h6">
                        Our Restaurant
                      </Typography>
                      <p>
                        The Cromal Hill Restaurant is the passion of the owner, Sheena, who has over 30 years of experience as a chef in some of the top hotels and restaurants
                        in Glasgow. The restaurant serves many classic dishes with a lean towards traditional Scottish cuisine, while offering others with an international
                        flair inspired by Sheena’s experience in the industry. The emphasis is on great food, beautifully cooked and providing something to suit every taste
                        from pure and simple to rich and indulgent.
                      </p>
                      <p>
                        Experience great wholesome cooking in a relaxed, comfortable environment where our fantastic friendly staff will provide a warm highland welcome.
                      </p>

                    </Paper>
                  </Grid>
                  <Grid item sm={6}>
                    <Paper className={classes.paper}>
                      <img alt="Our Restaurant" className={classes.full_width} src={process.env.PUBLIC_URL + "/img/restaurant-1.jpg"} />
                    </Paper>
                  </Grid>
                </Grid>
                <br/>
              </div>

              <div key="menus">
                <Grid container spacing={0}>
                  <Grid item sm={6}>
                    <Paper className={classes.paper}>
                      <img alt="Our Menus" className={classes.full_width} src={process.env.PUBLIC_URL + "/img/restaurant-2.jpg"} />
                    </Paper>
                  </Grid>
                  <Grid item sm={6}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.uppercase_heading} variant="h6">
                        Menus
                      </Typography>
                      <p><em>Please note, menu items and pricing may be subject to change. The menu linked below should be used for guidance only, please feel free to contact us for more information.</em></p>
                      <ul className={classes.clean_ul}>

                        <li className={classes.one_rem_margin}>
                          <MenuButton type="restaurant" environment={process.env.REACT_APP_ENVIRONMENT}></MenuButton>
                          {/*
                          <a rel="noopener noreferrer" target="_blank" href={process.env.PUBLIC_URL + "/menus/gunlodgehotel_menu_july_2019.pdf"}><Button variant="contained" color="primary">
                          View our menu
                        </Button></a>
                          */}
                        </li>
                        <li className={classes.one_rem_margin}>
                          <MenuButton type="drinks" environment={process.env.REACT_APP_ENVIRONMENT}></MenuButton>
                        </li>
                        <li className={classes.one_rem_margin}>
                          <MenuButton type="whisky" environment={process.env.REACT_APP_ENVIRONMENT}></MenuButton>
                        </li>
                      </ul>
                    </Paper>
                  </Grid>
                </Grid>
                <br/>
              </div>


              <div key="whisky">
                <Grid container spacing={0}>
                  <Grid item sm={6}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.uppercase_heading} variant="h6">
                        Whisky Bar
                      </Typography>
                      <p>
                      Whisky is arguably one of Scotland’s most distinguishing and characterising products. Our comfortable lounge bar hosts a wide variety of whisky from all over Scotland.
                      What better way to get a taste of Scotland than sampling our fine collection?
                      </p>
                      <p>
                      If whisky is not to your taste, we boast a selection of local real ale available on tap and a lovely handpicked wine list.
                      Relax next to our open fire and enjoy your stay.
                      </p>
                    </Paper>
                  </Grid>
                  <Grid item sm={6}>
                    <Paper className={classes.paper}>
                      <img alt="Whisky Bar" className={classes.full_width} src={process.env.PUBLIC_URL + "/img/restaurant-3.jpg"} />
                    </Paper>
                  </Grid>
                </Grid>
                <br/>
              </div>

              <div key="button" className={classes.flex_center}>
                <Link className={classes.anchor_center} to="/gallery">
                <Button variant="contained" color="primary">
                  View more images in our image gallery
                </Button>
                </Link>
              </div>

        </div>
      </div>
    </div>
  );
}
