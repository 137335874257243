import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import record_page_view from '../routing/analytics';

const background_image_path = `url(` + process.env.PUBLIC_URL + `/img/banner-hotel.jpg) center center`;

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "3em 10% 0 10%",
    maxWidth: "1366px",
    margin: "0 auto 2em auto",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  uppercase_heading: {
    textTransform: "uppercase",
    borderBottom: "1px solid #cccccc",
    marginBottom: "1em",
  },
  description_text: {
    marginBottom: "1em",
  },
  full_width: {
    width: "100%",
    maxWidth: "100%",
  },
  outer_container: {
    width: "100%",
    maxWidth: "100%",
  },
  image_header: {
    margin: 0,
    height: "400px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    background: background_image_path,
  },
  image_header_img: {
    width: "100%",
  },
  clean_ul: {
    listStyleType: "none",
    margin: "0",
    padding: 0,
  },
}));

export default function Hotel() {
  const classes = useStyles();

  useEffect(() => {
    var evt = document.createEvent('Event');
    evt.initEvent('load', false, false);
    window.dispatchEvent(evt);
  });

  return (
    <div className={classes.outer_container}>
      {record_page_view()}
      <div className={classes.image_header}>
      </div>

      <div className={classes.container}>
        <div className={classes.root}>

            <div key="info">
              <Grid container spacing={0}>
                <Grid item sm={6}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.uppercase_heading} variant="h6">
                      From Military House To Hotel
                    </Typography>
                    <p>
                      The Gun Lodge Hotel, formally known as the Cromal Lodge was built in 1769 following the completion of the nearby Fort George garrison in the wake of the battle of
                       Culloden in 1746. Fort George itself was built following the Jacobite uprising as an impregnable base for King George II’s army and was used in an effort to quell
                       the Jacobite threat. The fort took 21 years to complete by which time the Jacobite threat was extinguished. The fort today remains an active army barracks and
                       museum and is still regarded as one of the finest fortifications in Britain.
                    </p>
                    <p>
                      The Cromal Lodge, named for the mount at the base of which it stands, was built as a home & stables for high ranking officers from the fort and served as a military building for much of its history. With the changing role of Fort George through the First and Second World War, the use of the Cromal Lodge evolved and it is said to have even functioned as a hospital for a brief period of time. The Lodge then fell into disrepair in the late 50s -early 60s before being reopened by the Oram family as the Gun Lodge Hotel named as it is today.
                    </p>

                  </Paper>
                </Grid>
                <Grid item sm={6}>
                  <Paper className={classes.paper}>
                    <img alt="Gun Lodge Hotel" className={classes.full_width} src={process.env.PUBLIC_URL + "/img/hotel-2.jpg"} />
                  </Paper>
                </Grid>
              </Grid>
              <br/>
            </div>

            <div key="menus">
              <Grid container spacing={0}>
                <Grid item sm={6}>
                  <Paper className={classes.paper}>
                    <img alt="Gun Lodge Hotel" className={classes.full_width} src={process.env.PUBLIC_URL + "/img/hotel-1.jpg"} />
                  </Paper>
                </Grid>
                <Grid item sm={6}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.uppercase_heading} variant="h6">
                      An Adventure Awaits
                    </Typography>
                    <p>
                    The hotel is steeped in a near 250 year history which lives on through the tales passed down through generations. Relics of the past can be found in the grounds such as the intact stable stalls hidden in the hotel out-building and the military insignia carved in the gates. Myths have even developed regarding the lingering spirit of a broken hearted war mistress said to reside in hotel. The restaurant now situated in the hotel pays homage to this history and has been christened with the Cromal name.
                    </p>
                    <p>
                    <a rel="noopener noreferrer" target="_blank" href="https://www.booking.com/hotel/gb/the-gun-lodge.en-gb.html">
                      <Button variant="contained" color="primary">
                        Book your stay today!
                      </Button>
                    </a>
                    </p>
                    <p>
                    For more information regarding the history of the hotel or local area please do not hesitate to contact us.
                    </p>
                  </Paper>
                </Grid>
              </Grid>
              <br/>
            </div>

            <div key="reviews">
              <Grid container spacing={0}>
                <Grid item sm={6}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.uppercase_heading} variant="h6">
                      Reviews & Acolades
                    </Typography>
                    <ul className={classes.clean_ul}>
                      <div id="TA_certificateOfExcellence17" className="TA_certificateOfExcellence"><ul id="AxRzzu8yLmK" className="TA_links f0bPzjq1">
                        <li id="TScMJfaRC8js" className="fSrAZ7M">
                            <a rel="noopener noreferrer" target="_blank" href="https://www.tripadvisor.com/Hotel_Review-g780692-d610347-Reviews-The_Gun_Lodge_Hotel-Ardersier_Inverness_Scottish_Highlands_Scotland.html">
                              <img src="https://www.tripadvisor.com/img/cdsi/img2/awards/CoE2017_WidgetAsset-14348-2.png" alt="TripAdvisor" className="widCOEImg" id="CDSWIDCOELOGO"/>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div id="TA_excellent923" className="TA_excellent">
                        <ul id="cijSsGV7q64" className="TA_links fq64qj">
                          <li id="6e07GixVbTLO" className="w1BYJVehHX">
                            <a rel="noopener noreferrer" target="_blank" href="https://www.tripadvisor.com/">
                              <img src="https://static.tacdn.com/img2/widget/tripadvisor_logo_115x18.gif" alt="TripAdvisor" className="widEXCIMG" id="CDSWIDEXCLOGO"/>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div id="TA_rated164" className="TA_rated">
                        <ul id="Vy5vFwvEx8" className="TA_links 39SwPLVajvU">
                          <li id="s84HgvilcM" className="ryzRjdkuZ">
                            <a rel="noopener noreferrer" target="_blank" href="https://www.tripadvisor.com/">
                              <img src="https://www.tripadvisor.com/img/cdsi/img2/badges/ollie-11424-2.gif" alt="TripAdvisor"/>
                            </a>
                          </li>
                        </ul>
                      </div>

                      {/*<div id="TA_cdsscrollingravenarrow319" class="TA_cdsscrollingravenarrow">
                        <ul id="ZE79uH" class="TA_links gHq28QtOgMdM">
                          <li id="LGq0tsWcPsiB" class="r19xVQ6HzCd">
                            <a target="_blank" href="https://www.tripadvisor.com/">
                              <img src="https://static.tacdn.com/img2/t4b/Stacked_TA_logo.png" alt="TripAdvisor" class="widEXCIMG" id="CDSWIDEXCLOGO"/>
                            </a>
                          </li>
                        </ul>
                      </div>*/}

                      {/*<div id="TA_selfserveprop473" class="TA_selfserveprop">
                        <ul id="sCvTENq" class="TA_links zVOaIIgX6">
                          <li id="ku0yBeM57" class="fkiwZn1oO4k">
                            <a target="_blank" href="https://www.tripadvisor.com/">
                              <img src="https://www.tripadvisor.com/img/cdsi/img2/branding/150_logo-11900-2.png" alt="TripAdvisor"/>
                            </a>
                          </li>
                        </ul>
                      </div>*/}


                    </ul>
                  </Paper>
                </Grid>

              <Grid item sm={6}>
                <Paper className={classes.paper}>
                  <img alt="Our Menus" className={classes.full_width} src={process.env.PUBLIC_URL + "/img/hotel-3.jpg"} />
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
