import React from 'react'
import Button from '@material-ui/core/Button';
import styles from './MenuButton.module.css'

export const MenuButton = (props) => {

    const type = props.type
    const environment = props.environment

    const renderMenuPDFDownloadLink = () => {
        return `https://s3-eu-west-1.amazonaws.com/${environment}-gunlodgehotel.co.uk/dynamic/menus/${type}.pdf`
    }

    return (
        <a
            className={styles.menuDownloadButton}
            href={renderMenuPDFDownloadLink()}
            title={`Download ${type} Menu`}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Button variant="contained" color="primary">Download {type} Menu</Button>
        </a>
    )
}