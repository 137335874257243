import React from 'react'
//import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
//import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import record_page_view from '../routing/analytics';
//import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.min.css';

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "3em 10% 0 10%",
    maxWidth: "1366px",
    margin: "0 auto 2em auto",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  uppercase_heading: {
    textTransform: "uppercase",
    borderBottom: "1px solid #cccccc",
    margin: "2em 0 1em 0",
  },
  description_text: {
    marginBottom: "1em",
  },
  full_width: {
    width: "100%",
    maxWidth: "100%",
  },
  outer_container: {
    width: "100%",
    maxWidth: "100%",
  },
  image_header: {
    margin: 0,
  },
  clean_ul: {
    listStyleType: "none",
    margin: "0",
    padding: 0,
  },
  contact_form_container: {
    width: "80%",
    margin: "0 10% 3em 10%",
  },
  contact_form: {
    display: "flex",
    flexDirection: "column"
  },
  MuiFormControl_root: {
    padding: "1em",
  }
}));


function send_contact_form() {
  var client_name = document.getElementById("client_name_input").value;
  var client_email = document.getElementById("email_address_input").value;
  var client_message = document.getElementById("client_message_input").value;
  //
  //  Ok, now post to Carbon Mail.
  var payload = {
    client_name: client_name,
    client_email: client_email,
    client_message: client_message
  };
  console.log("payload", payload);

  var xhr = new XMLHttpRequest();
  var url = undefined;
  if(window.location.href.indexOf("localhost") > -1){
    //
    //  We need to send to the test endpoint.
    url = "https://rtmriujgxb.execute-api.eu-west-1.amazonaws.com/prod/test/send";
  }
  else{
    //
    //  This is a real request, use the production endpoint.
    url = "https://rtmriujgxb.execute-api.eu-west-1.amazonaws.com/prod/send";
  }
  xhr.open("POST", url, true);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
          var json = JSON.parse(xhr.responseText);
          console.log(json);
          console.log("ok - sent")
          document.getElementById("button").innerHTML = "Message sent successfully :)";
          document.getElementById("button").style.backgroundColor = "#298c2c";
      }
      if(xhr.status > 399){
        console.log("error, something went wrong with the request", xhr.responseText);
        document.getElementById("button").innerHTML = "Something went wrong, please contact us directly via email";
        document.getElementById("button").style.backgroundColor = "#b51b30";
      }
  };
  var data = JSON.stringify(payload);
  console.log("sending data to endpoint");
  xhr.send(data);
  document.getElementById("button").innerHTML = "Sending message...";
  document.getElementById("button").style.backgroundColor = "#626f7a";
  return false;
}

export default function Contact() {
  const classes = useStyles();
  //const notify_ok = () => toast.success("Your message was sent successfully, we'll be in contact soon :)");
  //const notify_failed = () => toast.error("Oh no! Something has gone wrong :( Please contact us using the email address on this page");

  return (
    <div className={classes.outer_container}>
      {record_page_view()}

      <div className={classes.container}>
        <div className={classes.root}>

          <div key="contact_form">
            <Grid container spacing={0}>
              <Grid item sm={12}>
                <Paper className={classes.paper}>
                <img alt="Company Logo" className="" src={process.env.PUBLIC_URL + "/gunlodgehotel_logo_dark.png"} />
                  <Typography className={classes.uppercase_heading} variant="h6">
                    Contact Us
                  </Typography>


                  <div className={classes.contact_form_container}>
                    <form className={classes.contact_form}>
                    <p>
                      Get in contact with us directly using the form below, someone from the Gun Lodge team will get back to you as quickly as they can.
                    </p>
                    <p>
                    After receiving your request, we'll send a confirmation email to you. If something goes wrong and you don't receive this email, you can connect with us directly using our email address <a href="mailto:info@gunlodgehotel.co.uk">info@gunlodgehotel.co.uk</a>.
                    </p>
                    <br/>
                      <FormControl>
                        <TextField variant="outlined" label="Your name" id="client_name_input" aria-describedby="client_name_input_h_t" />
                      </FormControl>
                      <br/>
                      <FormControl>
                        <TextField type="email" variant="outlined" label="Your email address" id="email_address_input" aria-describedby="email_address_input_h_t" />
                      </FormControl>
                      <br/>
                      <FormControl>
                        <TextField variant="outlined" label="Your message" multiline rowsMax="12" id="client_message_input" aria-describedby="client_message_input_h_t" />
                      </FormControl>
                      <br/>
                      <Button onClick={send_contact_form} variant="contained" id="button" color="primary">
                        Send message
                      </Button>
                    </form>
                  </div>

                </Paper>
              </Grid>
            </Grid>
            <br/>
          </div>

        </div>
        <br/><br/><br/><br/><br/>
      </div>
    </div>
  );
}
