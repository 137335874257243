import React, {useState, useEffect} from 'react'
import Typography from '@material-ui/core/Typography';
import styles from './OpeningTimesView.module.css'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {apiGetRequest} from '../js/utils'

const useStyles = makeStyles(theme => ({

    table: {
        color: "#cfcfcf"
    },
    uppercase_heading: {
        textTransform: "uppercase",
        borderBottom: "1px solid #cccccc",
        textAlign: "center",
        color: "#cfcfcf",
        width: "200px",
        margin: "0 auto 0.25rem auto",
    },
    textRight: {
        textAlign: "right"
    }
  }));
  

export const OpeningTimesView = (props) => {
    const classes = useStyles()
    //
    //  :props:
    const environment = props.environment

    //
    //  :statics:
    //
    //  :state:
    const [isRequestingOpeningTimes, setIsRequestingOpeningTimes] = useState(false)
    const [openingTimesResponse, setOpeningTimesResponse] = useState(null)
    const [openingTimesResponseError, setOpeningTimesResponseError] = useState(null)

    const renderCssClassForRow = (day) => {
        const dayMap = {
            "Sunday": 0,
            "Monday": 1,
            "Tuesday": 2,
            "Wednesday": 3,
            "Thursday": 4,
            "Friday": 5,
            "Saturday": 6,            
        }
        const todaysNumber = new Date().getDay()
        if(dayMap[day] === todaysNumber){
            return styles.today
        }
        return ""
    }

     //
    //  :onload:
    //  Functions that are called when we load the page.
    useEffect(() => {
        async function fetchData(){
            setIsRequestingOpeningTimes(true)
            const data = await apiGetRequest("/opening-times?key=list")
            
            if(!data){
                setOpeningTimesResponseError({})
                setOpeningTimesResponse(null)
            }else{
                setOpeningTimesResponseError(null)
                setOpeningTimesResponse(data[1])
            }
            setIsRequestingOpeningTimes(false)
        
        }
        if(!openingTimesResponse && !isRequestingOpeningTimes && !openingTimesResponseError){
            fetchData()
        }
    }, [isRequestingOpeningTimes, openingTimesResponse, openingTimesResponseError])

    return (
        <>
            {openingTimesResponse && (
                <Grid>
                    <br/>
                <Typography className={classes.uppercase_heading} variant="h6">Opening Hours</Typography>

                <table className={classes.table}>
                    <tbody>
                        {openingTimesResponse.map((dataForDay) => {
                            return (
                                <tr key={dataForDay[0]} className={renderCssClassForRow(dataForDay[0])}>
                                    <td className={classes.textRight}>{dataForDay[0]}</td> 
                                    <td>{dataForDay[1]} - {dataForDay[2]}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                </Grid>
            )}

            {isRequestingOpeningTimes && (
                <p>...</p>
            )} 

        </>
    )
}
