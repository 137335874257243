import React, {useState, useEffect} from 'react'

//
//  :code:
import {apiGetRequest, apiPatchRequest} from '../../js/utils'
import {TimeSelector} from '../TimeSelector'
import {AMPMSelector} from '../AMPMSelector'
import styles from './OpeningTimes.module.css'

export const OpeningTimes = (props) => {
    //
    //  :statics:

    //
    //  :state:
    //  Variables that are in our state.

    const [isRequestingOpeningTimes, setIsRequestingOpeningTimes] = useState(false)
    const [openingTimesResponse, setOpeningTimesResponse] = useState(null)
    const [openingTimesResponseError, setOpeningTimesResponseError] = useState(null)

    const [openingTimesDataWasLoadedIntoState, setOpeningTimesDataWasLoadedIntoState] = useState(false)
    const [mondayOpenHours, setMondayOpenHours] = useState("07")
    const [mondayOpenMinutes, setMondayOpenMinutes] = useState("00")
    const [mondayOpenAMPM, setMondayOpenAMPM] = useState("AM")
    const [mondayCloseHours, setMondayCloseHours] = useState("07")
    const [mondayCloseMinutes, setMondayCloseMinutes] = useState("00")
    const [mondayCloseAMPM, setMondayCloseAMPM] = useState("PM")

    const [tuesdayOpenHours, setTuesdayOpenHours] = useState("07")
    const [tuesdayOpenMinutes, setTuesdayOpenMinutes] = useState("00")
    const [tuesdayOpenAMPM, setTuesdayOpenAMPM] = useState("AM")
    const [tuesdayCloseHours, setTuesdayCloseHours] = useState("07")
    const [tuesdayCloseMinutes, setTuesdayCloseMinutes] = useState("00")
    const [tuesdayCloseAMPM, setTuesdayCloseAMPM] = useState("PM")

    const [wednesdayOpenHours, setWednesdayOpenHours] = useState("07")
    const [wednesdayOpenMinutes, setWednesdayOpenMinutes] = useState("00")
    const [wednesdayOpenAMPM, setWednesdayOpenAMPM] = useState("AM")
    const [wednesdayCloseHours, setWednesdayCloseHours] = useState("07")
    const [wednesdayCloseMinutes, setWednesdayCloseMinutes] = useState("00")
    const [wednesdayCloseAMPM, setWednesdayCloseAMPM] = useState("PM")

    const [thursdayOpenHours, setThursdayOpenHours] = useState("07")
    const [thursdayOpenMinutes, setThursdayOpenMinutes] = useState("00")
    const [thursdayOpenAMPM, setThursdayOpenAMPM] = useState("AM")
    const [thursdayCloseHours, setThursdayCloseHours] = useState("07")
    const [thursdayCloseMinutes, setThursdayCloseMinutes] = useState("00")
    const [thursdayCloseAMPM, setThursdayCloseAMPM] = useState("PM")

    const [fridayOpenHours, setFridayOpenHours] = useState("07")
    const [fridayOpenMinutes, setFridayOpenMinutes] = useState("00")
    const [fridayOpenAMPM, setFridayOpenAMPM] = useState("AM")
    const [fridayCloseHours, setFridayCloseHours] = useState("07")
    const [fridayCloseMinutes, setFridayCloseMinutes] = useState("00")
    const [fridayCloseAMPM, setFridayCloseAMPM] = useState("PM")

    const [saturdayOpenHours, setSaturdayOpenHours] = useState("07")
    const [saturdayOpenMinutes, setSaturdayOpenMinutes] = useState("00")
    const [saturdayOpenAMPM, setSaturdayOpenAMPM] = useState("AM")
    const [saturdayCloseHours, setSaturdayCloseHours] = useState("07")
    const [saturdayCloseMinutes, setSaturdayCloseMinutes] = useState("00")
    const [saturdayCloseAMPM, setSaturdayCloseAMPM] = useState("PM")

    const [sundayOpenHours, setSundayOpenHours] = useState("07")
    const [sundayOpenMinutes, setSundayOpenMinutes] = useState("00")
    const [sundayOpenAMPM, setSundayOpenAMPM] = useState("AM")
    const [sundayCloseHours, setSundayCloseHours] = useState("07")
    const [sundayCloseMinutes, setSundayCloseMinutes] = useState("00")
    const [sundayCloseAMPM, setSundayCloseAMPM] = useState("PM")

    //
    //  :events:
    //  Functions to handle events.
    const onClickUpdateOpeningTimes = async (event) => {
        event.preventDefault()
        const formData = {
            mondayOpenHours, mondayOpenMinutes, mondayOpenAMPM, mondayCloseHours, mondayCloseMinutes, mondayCloseAMPM,
            tuesdayOpenHours, tuesdayOpenMinutes, tuesdayOpenAMPM, tuesdayCloseHours, tuesdayCloseMinutes, tuesdayCloseAMPM,
            wednesdayOpenHours, wednesdayOpenMinutes, wednesdayOpenAMPM, wednesdayCloseHours, wednesdayCloseMinutes, wednesdayCloseAMPM,
            thursdayOpenHours, thursdayOpenMinutes, thursdayOpenAMPM, thursdayCloseHours, thursdayCloseMinutes, thursdayCloseAMPM,
            fridayOpenHours, fridayOpenMinutes, fridayOpenAMPM, fridayCloseHours, fridayCloseMinutes, fridayCloseAMPM,
            saturdayOpenHours, saturdayOpenMinutes, saturdayOpenAMPM, saturdayCloseHours, saturdayCloseMinutes, saturdayCloseAMPM,
            sundayOpenHours, sundayOpenMinutes, sundayOpenAMPM, sundayCloseHours, sundayCloseMinutes, sundayCloseAMPM,
        }
        const response = await apiPatchRequest("/opening-times", formData)
        console.log(response)
    }

    //
    //  :onload:
    //  Functions that are called when we load the page.
    useEffect(() => {
        async function fetchData(){
            setIsRequestingOpeningTimes(true)
            const data = await apiGetRequest("/opening-times")
            
            if(!data){
                setOpeningTimesResponseError({})
                setOpeningTimesResponse(null)
            }else{
                setOpeningTimesResponseError(null)
                setOpeningTimesResponse(data[1])
                setOpeningTimesDataWasLoadedIntoState(true)
            }
            setIsRequestingOpeningTimes(false)
        
        }
        if(!openingTimesResponse && !isRequestingOpeningTimes && !openingTimesResponseError){
            fetchData()
        }
    }, [isRequestingOpeningTimes, openingTimesResponse, openingTimesResponseError])

    useEffect(() => {
        if(openingTimesResponse && openingTimesDataWasLoadedIntoState){
            if(!openingTimesResponse.flat)      {
                console.error(`error, failed to load the opening times response (wrong format)`)
                return
            }
            setMondayOpenHours(openingTimesResponse.flat.mondayOpenHours)
            setMondayOpenMinutes(openingTimesResponse.flat.mondayOpenMinutes)
            setMondayOpenAMPM(openingTimesResponse.flat.mondayOpenAMPM)
            setMondayCloseHours(openingTimesResponse.flat.mondayCloseHours)
            setMondayCloseMinutes(openingTimesResponse.flat.mondayCloseMinutes)
            setMondayCloseAMPM(openingTimesResponse.flat.mondayCloseAMPM)
            
            setTuesdayOpenHours(openingTimesResponse.flat.tuesdayOpenHours)
            setTuesdayOpenMinutes(openingTimesResponse.flat.tuesdayOpenMinutes)
            setTuesdayOpenAMPM(openingTimesResponse.flat.tuesdayOpenAMPM)
            setTuesdayCloseHours(openingTimesResponse.flat.tuesdayCloseHours)
            setTuesdayCloseMinutes(openingTimesResponse.flat.tuesdayCloseMinutes)
            setTuesdayCloseAMPM(openingTimesResponse.flat.tuesdayCloseAMPM)

            setWednesdayOpenHours(openingTimesResponse.flat.wednesdayOpenHours)
            setWednesdayOpenMinutes(openingTimesResponse.flat.wednesdayOpenMinutes)
            setWednesdayOpenAMPM(openingTimesResponse.flat.wednesdayOpenAMPM)
            setWednesdayCloseHours(openingTimesResponse.flat.wednesdayCloseHours)
            setWednesdayCloseMinutes(openingTimesResponse.flat.wednesdayCloseMinutes)
            setWednesdayCloseAMPM(openingTimesResponse.flat.wednesdayCloseAMPM)

            setThursdayOpenHours(openingTimesResponse.flat.thursdayOpenHours)
            setThursdayOpenMinutes(openingTimesResponse.flat.thursdayOpenMinutes)
            setThursdayOpenAMPM(openingTimesResponse.flat.thursdayOpenAMPM)
            setThursdayCloseHours(openingTimesResponse.flat.thursdayCloseHours)
            setThursdayCloseMinutes(openingTimesResponse.flat.thursdayCloseMinutes)
            setThursdayCloseAMPM(openingTimesResponse.flat.thursdayCloseAMPM)

            setFridayOpenHours(openingTimesResponse.flat.fridayOpenHours)
            setFridayOpenMinutes(openingTimesResponse.flat.fridayOpenMinutes)
            setFridayOpenAMPM(openingTimesResponse.flat.fridayOpenAMPM)
            setFridayCloseHours(openingTimesResponse.flat.fridayCloseHours)
            setFridayCloseMinutes(openingTimesResponse.flat.fridayCloseMinutes)
            setFridayCloseAMPM(openingTimesResponse.flat.fridayCloseAMPM)

            setSaturdayOpenHours(openingTimesResponse.flat.saturdayOpenHours)
            setSaturdayOpenMinutes(openingTimesResponse.flat.saturdayOpenMinutes)
            setSaturdayOpenAMPM(openingTimesResponse.flat.saturdayOpenAMPM)
            setSaturdayCloseHours(openingTimesResponse.flat.saturdayCloseHours)
            setSaturdayCloseMinutes(openingTimesResponse.flat.saturdayCloseMinutes)
            setSaturdayCloseAMPM(openingTimesResponse.flat.saturdayCloseAMPM)

            setSundayOpenHours(openingTimesResponse.flat.sundayOpenHours)
            setSundayOpenMinutes(openingTimesResponse.flat.sundayOpenMinutes)
            setSundayOpenAMPM(openingTimesResponse.flat.sundayOpenAMPM)
            setSundayCloseHours(openingTimesResponse.flat.sundayCloseHours)
            setSundayCloseMinutes(openingTimesResponse.flat.sundayCloseMinutes)
            setSundayCloseAMPM(openingTimesResponse.flat.sundayCloseAMPM)

            setOpeningTimesDataWasLoadedIntoState(true)
        }        
    }, [openingTimesResponse, openingTimesDataWasLoadedIntoState])

    return (
        <div>
        <h1>Opening Times</h1>
        <p>The values below show the opening times as they are currently set.</p>

        <p>Note, any change made here will be instantly visible on the website.</p>

        <form>            
            <div className={styles.timesContainer}>
            <div>
                <h4>Monday</h4> <label>Open</label>
                <div>
                   
                    <TimeSelector 
                        name="mondayOpenHours"
                        mode="hours"
                        value={mondayOpenHours}
                        setter={setMondayOpenHours}
                    />
                    <TimeSelector 
                        name="mondayOpenMinutes"
                        mode="minutes"
                        value={mondayOpenMinutes}
                        setter={setMondayOpenMinutes}
                    />
                    <AMPMSelector
                        name="mondayOpenAMPM"
                        value={mondayOpenAMPM}
                        setter={setMondayOpenAMPM}
                    />
                </div>
                <label>Close</label>
                <div>
                    
                    <TimeSelector 
                        name="mondayCloseHours"
                        mode="hours"
                        value={mondayCloseHours}
                        setter={setMondayCloseHours}
                    />
                    <TimeSelector 
                        name="mondayCloseMinutes"
                        mode="minutes"
                        value={mondayCloseMinutes}
                        setter={setMondayCloseMinutes}
                    />
                    <AMPMSelector
                        name="mondayCloseAMPM"
                        value={mondayCloseAMPM}
                        setter={setMondayCloseAMPM}
                    />
                </div>
            </div>
            <div>
                <h4>Tuesday</h4>
                <label>Open</label>
                <div>
                    
                    <TimeSelector 
                        name="tuesdayOpenHours"
                        mode="hours"
                        value={tuesdayOpenHours}
                        setter={setTuesdayOpenHours}
                    />
                    <TimeSelector 
                        name="tuesdayOpenMinutes"
                        mode="minutes"
                        value={tuesdayOpenMinutes}
                        setter={setTuesdayOpenMinutes}
                    />
                    <AMPMSelector
                        name="tuesdayOpenAMPM"
                        value={tuesdayOpenAMPM}
                        setter={setTuesdayOpenAMPM}
                    />
                </div>
                <label>Close</label>
                <div>                    
                    <TimeSelector 
                        name="tuesdayCloseHours"
                        mode="hours"
                        value={tuesdayCloseHours}
                        setter={setTuesdayCloseHours}
                    />
                    <TimeSelector 
                        name="tuesdayCloseMinutes"
                        mode="minutes"
                        value={tuesdayCloseMinutes}
                        setter={setTuesdayCloseMinutes}
                    />
                    <AMPMSelector
                        name="tuesdayCloseAMPM"
                        value={tuesdayCloseAMPM}
                        setter={setTuesdayCloseAMPM}
                    />
                </div>
            </div>
            <div>
                <h4>Wednesday</h4>
                <label>Open</label>
                <div>                    
                    <TimeSelector 
                        name="wednesdayOpenHours"
                        mode="hours"
                        value={wednesdayOpenHours}
                        setter={setWednesdayOpenHours}
                    />
                    <TimeSelector 
                        name="wednesdayOpenMinutes"
                        mode="minutes"
                        value={wednesdayOpenMinutes}
                        setter={setWednesdayOpenMinutes}
                    />
                    <AMPMSelector
                        name="wednesdayOpenAMPM"
                        value={wednesdayOpenAMPM}
                        setter={setWednesdayOpenAMPM}
                    />
                </div>
                <label>Close</label>
                <div>                    
                    <TimeSelector 
                        name="wednesdayCloseHours"
                        mode="hours"
                        value={wednesdayCloseHours}
                        setter={setWednesdayCloseHours}
                    />
                    <TimeSelector 
                        name="wednesdayCloseMinutes"
                        mode="minutes"
                        value={wednesdayCloseMinutes}
                        setter={setWednesdayCloseMinutes}
                    />
                    <AMPMSelector
                        name="wednesdayCloseAMPM"
                        value={wednesdayCloseAMPM}
                        setter={setWednesdayCloseAMPM}
                    />
                </div>
            </div>
            <div>
                <h4>Thursday</h4>
                <label>Open</label>
                <div>
                    
                    <TimeSelector 
                        name="thursdayOpenHours"
                        mode="hours"
                        value={thursdayOpenHours}
                        setter={setThursdayOpenHours}
                    />
                    <TimeSelector 
                        name="thursdayOpenMinutes"
                        mode="minutes"
                        value={thursdayOpenMinutes}
                        setter={setThursdayOpenMinutes}
                    />
                    <AMPMSelector
                        name="thursdayOpenAMPM"
                        value={thursdayOpenAMPM}
                        setter={setThursdayOpenAMPM}
                    />
                </div>
                <label>Close</label>
                <div>
                    <TimeSelector 
                        name="thursdayCloseHours"
                        mode="hours"
                        value={thursdayCloseHours}
                        setter={setThursdayCloseHours}
                    />
                    <TimeSelector 
                        name="thursdayCloseMinutes"
                        mode="minutes"
                        value={thursdayCloseMinutes}
                        setter={setThursdayCloseMinutes}
                    />
                    <AMPMSelector
                        name="thursdayCloseAMPM"
                        value={thursdayCloseAMPM}
                        setter={setThursdayCloseAMPM}
                    />
                </div>
            </div>
            <div>
                <h4>Friday</h4>
                <label>Open</label>
                <div>
                    <TimeSelector 
                        name="fridayOpenHours"
                        mode="hours"
                        value={fridayOpenHours}
                        setter={setFridayOpenHours}
                    />
                    <TimeSelector 
                        name="fridayOpenMinutes"
                        mode="minutes"
                        value={fridayOpenMinutes}
                        setter={setFridayOpenMinutes}
                    />
                    <AMPMSelector
                        name="fridayOpenAMPM"
                        value={fridayOpenAMPM}
                        setter={setFridayOpenAMPM}
                    />
                </div>
                <label>Close</label>
                <div>
                    <TimeSelector 
                        name="fridayCloseHours"
                        mode="hours"
                        value={fridayCloseHours}
                        setter={setFridayCloseHours}
                    />
                    <TimeSelector 
                        name="fridayCloseMinutes"
                        mode="minutes"
                        value={fridayCloseMinutes}
                        setter={setFridayCloseMinutes}
                    />
                    <AMPMSelector
                        name="fridayCloseAMPM"
                        value={fridayCloseAMPM}
                        setter={setFridayCloseAMPM}
                    />
                </div>
            </div>
            <div>
                <h4>Saturday</h4>
                <label>Open</label>
                <div>
                    <TimeSelector 
                        name="saturdayOpenHours"
                        mode="hours"
                        value={saturdayOpenHours}
                        setter={setSaturdayOpenHours}
                    />
                    <TimeSelector 
                        name="saturdayOpenMinutes"
                        mode="minutes"
                        value={saturdayOpenMinutes}
                        setter={setSaturdayOpenMinutes}
                    />
                    <AMPMSelector
                        name="saturdayOpenAMPM"
                        value={saturdayOpenAMPM}
                        setter={setSaturdayOpenAMPM}
                    />
                </div>
                <label>Close</label>
                <div>
                    <TimeSelector 
                        name="saturdayCloseHours"
                        mode="hours"
                        value={saturdayCloseHours}
                        setter={setSaturdayCloseHours}
                    />
                    <TimeSelector 
                        name="saturdayCloseMinutes"
                        mode="minutes"
                        value={saturdayCloseMinutes}
                        setter={setSaturdayCloseMinutes}
                    />
                    <AMPMSelector
                        name="saturdayCloseAMPM"
                        value={saturdayCloseAMPM}
                        setter={setSaturdayCloseAMPM}
                    />
                </div>
            </div>
            <div>
                <h4>Sunday</h4>
                <label>Open</label>
                <div>                    
                    <TimeSelector 
                        name="sundayOpenHours"
                        mode="hours"
                        value={sundayOpenHours}
                        setter={setSundayOpenHours}
                    />
                    <TimeSelector 
                        name="sundayOpenMinutes"
                        mode="minutes"
                        value={sundayOpenMinutes}
                        setter={setSundayOpenMinutes}
                    />
                    <AMPMSelector
                        name="sundayOpenAMPM"
                        value={sundayOpenAMPM}
                        setter={setSundayOpenAMPM}
                    />
                </div>
                <label>Close</label>
                <div>                    
                    <TimeSelector 
                        name="sundayCloseHours"
                        mode="hours"
                        value={sundayCloseHours}
                        setter={setSundayCloseHours}
                    />
                    <TimeSelector 
                        name="sundayCloseMinutes"
                        mode="minutes"
                        value={sundayCloseMinutes}
                        setter={setSundayCloseMinutes}
                    />
                    <AMPMSelector
                        name="sundayCloseAMPM"
                        value={sundayCloseAMPM}
                        setter={setSundayCloseAMPM}
                    />
                </div>
            </div>
            </div>
            <br/>
            <input onClick={onClickUpdateOpeningTimes} type='submit' value='Update' />

        </form>

    </div>

    )
}