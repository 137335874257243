import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import record_page_view from '../routing/analytics';

const background_image_path = `url(` + process.env.PUBLIC_URL + `/img/banner-accommodation.jpg) center center`;

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "3em 10% 0 10%",
    maxWidth: "1366px",
    margin: "0 auto 2em auto",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  uppercase_heading: {
    textTransform: "uppercase",
    borderBottom: "1px solid #cccccc",
    marginBottom: "1em",
  },
  description_text: {
    marginBottom: "1em",
  },
  full_width: {
    width: "100%",
    maxWidth: "100%",
  },
  outer_container: {
    width: "100%",
    maxWidth: "100%",
  },
  image_header: {
    margin: 0,
    height: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: background_image_path,
  },
  clean_ul: {
    listStyleType: "none",
    margin: "0",
    padding: 0,
  },
  anchor_center: {
    textAlign: "center",
  },
  flex_center: {
    display: "flex",
    justifyContent: "center",
  }
}));

export default function Accommodation() {
  const classes = useStyles();

  return (
    <div className={classes.outer_container}>
    {record_page_view()}

      <div className={classes.image_header}>
      </div>


      <div className={classes.container}>
        <div className={classes.root}>

            <div key="info">
              <Grid container spacing={0}>
                <Grid item sm={6}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.uppercase_heading} variant="h6">
                      Our Accommodation
                    </Typography>
                    <p>
                      With a wide selection of Rooms we can tailor to almost any requirements.
                    </p>
                    <p>
                      Our ten comfortable en-suite rooms offer a fantastic stay in the heart of the highlands. Available in double or twin, with options available
                      to accommodate a family, we can be sure to meet all of your needs.
                    </p>

                  </Paper>
                </Grid>
                <Grid item sm={6}>
                  <Paper className={classes.paper}>
                    <img alt="Gun Lodge Hotel" className={classes.full_width} src={process.env.PUBLIC_URL + "/img/accommodation-1.jpg"} />
                  </Paper>
                </Grid>
              </Grid>
              <br/>
            </div>

            <div key="menus">
              <Grid container spacing={0}>
                <Grid item sm={6}>
                  <Paper className={classes.paper}>
                    <img alt="Gun Lodge Hotel" className={classes.full_width} src={process.env.PUBLIC_URL + "/img/accommodation-2.jpg"} />
                  </Paper>
                </Grid>
                <Grid item sm={6}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.uppercase_heading} variant="h6">
                      Our En-suite Rooms
                    </Typography>
                    <p>
                    All of our rooms come with television, free Wi-Fi and tea and coffee making facilities, with the possibility of upgrading to one of our
                    executive rooms which provide comfortable sofas, fridges and ironing facilities.
                    </p>
                    <p>
                    If you are unable to manage stairs we also have three ground floor access rooms, one with a fully fitted disabled access bathroom.
                    </p>
                    <p>
                    Continental breakfast is included with your stay, however, if you are looking to indulge a little further a cooked breakfast can be arranged.
                    All served within the charming Cromal Hill Restaurant.
                    </p>
                  </Paper>
                </Grid>
              </Grid>
              <br/>
            </div>

            <div key="button" className={classes.flex_center}>
              <Link className={classes.anchor_center} to="/gallery">
              <Button variant="contained" color="primary">
                View more images in our image gallery
              </Button>
              </Link>
            </div>



        </div>
      </div>
    </div>
  );
}
