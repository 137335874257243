import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import record_page_view from '../routing/analytics';

const background_image_path = `url(` + process.env.PUBLIC_URL + `/img/banner-activities.jpg) center center`;

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "3em 10% 0 10%",
    maxWidth: "1366px",
    margin: "0 auto 2em auto",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  uppercase_heading: {
    textTransform: "uppercase",
    borderBottom: "1px solid #cccccc",
    marginBottom: "1em",
  },
  description_text: {
    marginBottom: "1em",
  },
  full_width: {
    width: "100%",
    maxWidth: "100%",
  },
  outer_container: {
    width: "100%",
    maxWidth: "100%",
  },
  image_header: {
    margin: 0,
    height: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: background_image_path,
  },
}));


export default function Activities() {
  const classes = useStyles();
  const activities = [
    {
      "id": "fort_george",
      "heading": "Fort George (1.7 miles)",
      "subheading": "A great vantage point for dolphin sighting",
      "descriptions": [
        "With historic links to the hotel, Fort George is the finest example of 18th century military architecture anywhere in Britain. " +
        "The Fort's Point makes an excellent vantage point for the views of the popular Moray Firth dolphins."
      ],
      "external_link": "https://www.visitscotland.com/info/see-do/fort-george-p245961",
      "image_path": "/img/activity-1.jpg"
    },
    {
      "id": "golf",
      "heading": "Golf (4.3 miles)",
      "subheading": "Enjoy one of our most well known past-times",
      "descriptions": [
        ">Inverness is undoubtedly a fantastic destination for those who love to golf. Travel to any of the 33 courses within an hour’s drive or visit" +
        " the nearby Royal Dornoch, Nairn or the new Castle Stuart Links located under 5 minutes from the hotel."
      ],
      "external_link": "https://www.castlestuartgolf.com",
      "image_path": "/img/activity-7.jpg"
    },
    {
      "id": "cawdor",
      "heading": "Cawdor Castle (6.1 miles)",
      "subheading": "A historic Scottish Castle",
      "descriptions": [
        "Dating from the late 14th century, Cawdor Castle is a must see. Originally built as a private fortress by the Thanes of Cawdor, the building " +
        "is steeped in history. Visit the ancient medieval tower built around the legendary holly tree, or learn about the Cawdor family name which is " +
        "linked to Shakespeare’s Macbeth."
      ],
      "external_link": "https://www.cawdorcastle.com/home.aspx",
      "image_path": "/img/activity-3.jpg"
    },
    {
      "id": "culloden",
      "heading": "Culloden Battlefield (8.8 miles)",
      "subheading": "A historic Scottish battlefield",
      "descriptions": [
        "The site of the last bloody battle of the Jacobite uprising, Culloden battlefield and visitor centre chronicles the battle which " +
        "had a huge impact of Scottish history."
      ],
      "external_link": "https://www.nts.org.uk/visit/places/culloden",
      "image_path": "/img/activity-2.jpg"
    },
    {
      "id": "loch_ness",
      "heading": "Loch Ness & Urqhuart Castle (24.9 miles)",
      "subheading": "A famous Scottish Lake",
      "descriptions": [
        "Book a small boat cruise on the loch and search for the elusive loch ness monster then beach on and explore the much fought over Urquart Castle," +
        " which saw many bloody feuds from the 1200s- 1600s. The castle was a prominent figure in the Scots’ struggle for independence and came under " +
        "control of Robert the Bruce when he became King of Scots in 1309."
      ],
      "external_link": "https://www.jacobite.co.uk/",
      "image_path": "/img/activity-5.jpg"
    },
    {
      "id": "dunrobin",
      "heading": "Dunrobin Castle (61.6 miles)",
      "subheading": "A historic Scottish House",
      "descriptions": [
        "Dunrobin Castle is the furthest north of all of Scotland’s great houses, and the largest in the highlands with 189 rooms. The castle resembles" +
        " a French chateau with its conical spires and dates back to the early 1300’s. The castle was home to the Earls and later the Dukes of Sutherland." +
        " Don’t forget to catch the fantastic falconry display."
      ],
      "external_link": "http://www.dunrobincastle.co.uk/",
      "image_path": "/img/activity-4.jpg"
    },
    {
      "id": "fishing",
      "heading": "Fishing",
      "subheading": "Enjoy one of our more historic past-times",
      "descriptions": [
        "Fishing in the highlands is second to none, whether you are looking for wild brown trout salmon or sea trout there is plenty on offer in the " +
        "local area. If you are looking for an easier day why not visit one of the local fisheries and break out the fly rod."
      ],
      "external_link": "https://www.visitscotland.com/info/see-do/search-results?prodtypes=acti%2Cattr%2Creta&loc=Inverness&locplace=7771&locprox=0&cat=fishing",
      "image_path": "/img/activity-8.jpg"
    },
    {
      "id": "whisky",
      "heading": "Whisky tours",
      "subheading": "Enjoy our famous Scottish drink",
      "descriptions": [
        "It wouldn’t be a trip to Scotland without a visit to one of our many wonderful distilleries. Pick up the whisky trail or track down your " +
        "favourite malt. Not sure which ones to choose? Simply sample the whisky in our whisky bar before you go!"
      ],
      "external_link": "https://www.visitscotland.com/see-do/food-drink/whisky/distilleries/",
      "image_path": "/img/activity-6.jpg"
    },
    {
      "id": "mountain_biking",
      "heading": "Mountain biking",
      "subheading": "Ride across the Scottish Highlands",
      "descriptions": [
        "One of the best ways to experience the mountains of Scotland is by bike, within an hour’s drive of the hotel exists endless amounts of forestry " +
        "trails to suit all levels. Looking for something more challenging? Ask us about the lesser known local trails you won’t find on the maps."
      ],
      "external_link": "https://www.visitscotland.com/see-do/active/mountain-biking/",
      "image_path": "/img/activity-9.jpg"
    },
    {
      "id": "skiing",
      "heading": "Skiing & snowboarding",
      "subheading": "Access Winter sports on our doorstep",
      "descriptions": [
        "Cairngorm Mountain is situated around 40 minutes from the hotel. In the peak of winter this offers some of the best snow sports in the highlands. Snowboard and ski hire available from Aviemore and the mountain uplift station."
      ],
      "external_link": "https://www.cairngormmountain.co.uk",
      "image_path": "/img/activity-10.jpg"
    }
  ];

  return (
    <div className={classes.outer_container}>
      {record_page_view()}
      <div className={classes.image_header}>
      </div>

      <div className={classes.container}>
        <div className={classes.root}>

          {activities.map((activity) => {
            return (
              <div key={activity.id}>
                <Grid container spacing={0}>
                  <Grid item sm={6}>
                    <Paper className={classes.paper}>
                      <Typography className={classes.uppercase_heading} variant="h6">
                        {activity.heading}
                      </Typography>
                      <p>
                        {activity.subheading}
                      </p>
                      <p>
                        {activity.descriptions}
                      </p>
                      <a rel="noopener noreferrer" href={activity.external_link} target="_blank"><Button variant="contained" color="primary">
                        View More Information »
                      </Button></a>
                    </Paper>
                  </Grid>
                  <Grid item sm={6}>
                    <Paper className={classes.paper}>
                      <img alt={activity.heading} className={classes.full_width} src={process.env.PUBLIC_URL + activity.image_path} />
                    </Paper>
                  </Grid>
                </Grid>
                <br/>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}
