import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import record_page_view from '../routing/analytics';

const background_image_path = `url(` + process.env.PUBLIC_URL + `/img/banner-index.jpg) center center`;

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "3em 10% 0 10%",
    maxWidth: "1366px",
    margin: "0 auto 2em auto",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  uppercase_heading: {
    textTransform: "uppercase",
    borderBottom: "1px solid #cccccc",
    margin: "1em 0 1em 0",
  },
  description_text: {
    marginBottom: "1em",
  },
  full_width: {
    width: "100%",
    maxWidth: "475px",
  },
  outer_container: {
    width: "100%",
    maxWidth: "100%",
  },
  image_header: {
    margin: 0,
    height: "95vh",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    background: background_image_path,
  },
  anchor: {
    textDecoration: "none",
    color: "#888888",
    fontWeight: "700",
  },
  home_background: {
    background: "rgba(0,0,0,0.8)",
    width: "100%",
    textAlign: "center",
    color: "#ffffff",
    padding: "3em 1em 3em 1em",
  },
  flex_center: {
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
  },
  heading_line_one: {
    textTransform: "uppercase",
    fontSize: "2.75em",
    margin: 0,
  },
  heading_line_two: {
    textTransform: "uppercase",
    fontSize: "2.05em",
    margin: 0,
  },
  heading_line_three: {
    textTransform: "uppercase",
    fontSize: "1.5em",
    margin: "0.5em",
  },
}));


export default function Home() {
  const classes = useStyles();

  return (
    <div className={classes.outer_container}>
      {record_page_view()}
      <div className={classes.image_header} key="xyz">
        <div className={classes.home_background}>
          <h1 className={classes.heading_line_one}>The Gun Lodge Hotel</h1>
          <h3 className={classes.heading_line_two}>& Cromal Hill Restaurant</h3>
          <hr/>
          <h4 className={classes.heading_line_three}>Experience Scotland's History</h4>
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.root}>
          <Grid container spacing={0}>
            <Grid item md={6}>
              <Paper className={classes.paper}>
              <Link className={classes.anchor} to="/hotel">
                <Typography className={classes.uppercase_heading} variant="h6">
                  Welcome to the Gun Lodge Hotel
                </Typography>
              </Link>
                <p>
                  The Gun Lodge Hotel is nestled in the quiet and historic village of Ardersier, conveniently located three miles from the airport and 25 minutes from the
                  centre of Inverness. With our historic building, ample parking, comfortable Cromal restaurant & whisky bar and ten charismatic en-suite bedrooms the hotel
                  is perfect for all occasions.
                </p>
                <p>
                  Whether looking for a quick stop over or the perfect base to explore everything that the Highlands has to offer, a warm Highland welcome awaits.
                </p>
                <div key="button_hotel" className={classes.flex_center}>
                  <Link className={classes.anchor_center} to="/hotel">
                  <Button variant="contained" color="primary">
                    More information »
                  </Button>
                  </Link>
                </div>
                <p>
                  <em>Please note: By continuing to browse our site you are agreeing to our <Link className={classes.anchor} to="/policy">Privacy Policy</Link>.</em>
                </p>
              </Paper>
            </Grid>
            <Grid item md={6}>
              <Paper className={classes.paper}>
              <Link className={classes.anchor} to="/hotel">
                <img alt="Gun Lodge Hotel" className={classes.full_width} src={process.env.PUBLIC_URL + "/img/index-1.jpg"} />
              </Link>
              </Paper>
            </Grid>
          </Grid>

          <br/>

          <Grid container spacing={0}>
            <Grid item md={6}>
              <Paper className={classes.paper}>
                <Link className={classes.anchor} to="/restaurant">
                  <Typography className={classes.uppercase_heading} variant="h6">
                    Cromal Hill Restaurant
                  </Typography>
                </Link>

                <p>
                  The Cromal Hill Restaurant within the Hotel, takes owner Sheena’s 30+ years of cheffing and passion for food to provide fantastic dining with a lean towards
                  traditional Scottish cuisine. Our whisky bar provides a relaxing atmosphere with our wide variety of regional Scottish whisky, real ale on tap, handpicked wine
                  list and open fire.
                </p>
                <div key="button_restuarant" className={classes.flex_center}>
                  <Link className={classes.anchor_center} to="/restaurant">
                  <Button variant="contained" color="primary">
                    More information »
                  </Button>
                  </Link>
                </div>
              </Paper>
            </Grid>
            <Grid item md={6}>
              <Paper className={classes.paper}>
                <Link className={classes.anchor} to="/restaurant">
                <img alt="Cromal Hill Restaurant" className={classes.full_width} src={process.env.PUBLIC_URL + "/img/index-2.jpg"} />
                </Link>
              </Paper>
            </Grid>
          </Grid>

        </div>
      </div>
    </div>
  );
}
