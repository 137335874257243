import React, {useState, useEffect} from 'react'

//
//  :code:
import {apiGetRequest, apiPatchRequest} from '../../js/utils'

export const Banner = (props) => {
    
    //
    //  :state:
    const [isRequestingBanner, setIsRequestingBanner] = useState(false)
    const [bannerResponse, setBannerResponse] = useState(null)
    const [bannerResponseError, setBannerResponseError] = useState(null)

    const [bannerEnabled, setBannerEnabled] = useState(false)
    const [bannerTitle, setBannerTitle] = useState("")
    const [bannerSubtitle, setBannerSubtitle] = useState("")

    //
    //  :statics:

    //
    //  :events:
    //  Functions to handle events.
    const onClickUploadBanner = async (event) => {
        event.preventDefault()
        const formData = {
            title: bannerTitle,
            subtitle: bannerSubtitle,
            enabled: bannerEnabled
        }
        const response = await apiPatchRequest("/banner", formData)
        console.log(response)
    }

    //
    //  :onload:
    useEffect(() => {
        async function fetchData() {
            setIsRequestingBanner(true)
            const data = await apiGetRequest("/banner")
            console.log(data)
            if(!data){
                setBannerResponseError({})
                setBannerResponse(null)
            }else{
                setBannerResponseError(null)
                setBannerResponse(data[1])
            }
            setIsRequestingBanner(false)
        }
        if(!bannerResponse && !isRequestingBanner && !bannerResponseError){
            fetchData();
        }
      }, [isRequestingBanner, bannerResponse, bannerResponseError]);


    useEffect(() => {
        if(bannerResponse && !bannerTitle){
            setBannerEnabled(bannerResponse.enabled)
            setBannerTitle(bannerResponse.title)
            setBannerSubtitle(bannerResponse.subtitle)
        }
        
    }, [bannerResponse, bannerTitle])

    return (
        <div>
            <h1>Banner</h1>
            
            <form>

                    <div>
                        <label>Enabled</label>
                        <input 
                            type="checkbox" 
                            name="enabled" 
                            value={bannerEnabled}
                            checked={bannerEnabled}
                            onChange={e => setBannerEnabled(e.target.checked)}
                        />
                    </div>

                    <div>
                        <label>Title</label>
                        <input 
                            type="text" 
                            name="title"
                            value={bannerTitle}
                            onChange={e => setBannerTitle(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Subtitle</label>
                        <input 
                            type="text" 
                            name="subtitle" 
                            value={bannerSubtitle}
                            onChange={e => setBannerSubtitle(e.target.value)}
                        />
                    </div>


                    
                    <input onClick={onClickUploadBanner} type='submit' value='Update Banner' />
            </form>
        </div>
    )
}