import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import record_page_view from '../routing/analytics';

const background_image_path = `url(` + process.env.PUBLIC_URL + `/img/banner-hotel.jpg) center center`;

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "3em 10% 0 10%",
    maxWidth: "900px",
    margin: "0 auto 2em auto",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  uppercase_heading: {
    textTransform: "uppercase",
    borderBottom: "1px solid #cccccc",
    marginBottom: "1em",
  },
  description_text: {
    marginBottom: "1em",
  },
  full_width: {
    width: "auto",
    display: "block",
    margin: "0 auto",
  },
  outer_container: {
    width: "100%",
    maxWidth: "100%",
  },
  image_header: {
    margin: 0,
    height: "400px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    background: background_image_path,
  },
  image_header_img: {
    width: "100%",
  },
  clean_ul: {
    margin: "0",
    padding: 0,
  },
}));

export default function Sitemap() {
  const classes = useStyles();

  return (
    <div className={classes.outer_container}>
      {record_page_view()}

      <div className={classes.container}>
        <div className={classes.root}>

            <div key="privacy">
              <Grid container spacing={0}>
                <Grid item sm={12}>
                  <Paper className={classes.paper}>
                    <img alt="Company Logo" className={classes.full_width} src={process.env.PUBLIC_URL + "/gunlodgehotel_logo_dark.png"} />
                    <br/>
                    <Typography className={classes.uppercase_heading} variant="h6">
                      Sitemap
                    </Typography>



                    <p>To assist with navigation, for humans and machines, this page provides a single set of links to all resources of this application and any linked resources.</p>



                    <p>Internal Pages</p>
                    <ul className={classes.clean_ul}>
                      <li>
                      <Link className={classes.anchor} to="/">
                        Home
                      </Link>
                      </li>
                      <li>
                      <Link className={classes.anchor} to="/contact">
                        Contact
                      </Link>
                      </li>
                      <li>
                      <Link className={classes.anchor} to="/gallery">
                        Gallery
                      </Link>
                      </li>
                      <li>
                      <Link className={classes.anchor} to="/restaurant">
                        Restaurant
                      </Link>
                      </li>
                      <li>
                      <Link className={classes.anchor} to="/hotel">
                        Hotel
                      </Link>
                      </li>
                      <li>
                      <Link className={classes.anchor} to="/policy">
                        Privacy Policy
                      </Link>
                      </li>
                      <li>
                      <Link className={classes.anchor} to="/sitemap">
                        Sitemap
                      </Link>
                      </li>
                    </ul>

                    <br/>

                    <p>External Pages</p>
                    <ul className={classes.clean_ul}>
                      <li>
                      <a rel="noopener noreferrer" target="_blank" href="https://www.booking.com/hotel/gb/the-gun-lodge.en-gb.html">Booking @ booking.com</a>
                      </li>
                      <li>
                      <a rel="noopener noreferrer" target="_blank" href="https://via.eviivo.com/en-en/Google/gunlodgeiv2">Booking @ eviivo.com</a>
                      </li>
                      <li>
                      <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/GunLodgeHotel/">Our Facebook</a>
                      </li>
                      <li>
                      <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/gunlodge">Our Twitter</a>
                      </li>
                      <li>
                      <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/gunlodgehotel/">Our Instagram</a>
                      </li>
                      <li>
                      <a rel="noopener noreferrer" target="_blank" href="https://www.google.pt/maps/place/The+Gun+Lodge+Hotel+%26+Cromal+Restaurant/@57.5719,-4.041345,17z/data=!3m1!4b1!4m8!3m7!1s0x488f789901dcf8ad:0x2653e1173d50309c!5m2!4m1!1i2!8m2!3d57.5719!4d-4.039151">Google Maps location</a>
                      </li>
                    </ul>
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

                  </Paper>
                </Grid>
              </Grid>
              <br/>
            </div>
          </div>

      </div>
    </div>
  );
}
