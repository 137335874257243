import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import {apiPostRequest} from '../js/utils'
import {OpeningTimes} from '../components/admin/OpeningTimes'
import {Banner} from '../components/admin/Banner'
import {Menus} from '../components/admin/Menus'

import styles from './admin.module.css'
import { MenuButton } from '../components/MenuButton'
import { OpeningTimesView } from '../components/OpeningTimesView'
import { BannerView } from '../components/BannerView'



const useStyles = makeStyles(theme => ({
    container: {
      width: "100%",
      padding: "3em 10% 0 10%",
      maxWidth: "1600px",
      margin: "0 auto 2em auto",
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,

      display: "flex",
      flexDirection: "column",
      marginBottom: "3rem"
    },
    uppercase_heading: {
      textTransform: "uppercase",
      borderBottom: "1px solid #cccccc",
      marginBottom: "1em",
    },
    description_text: {
      marginBottom: "1em",
    },
    full_width: {
      width: "100%",
      maxWidth: "100%",
    },
    outer_container: {
      width: "100%",
      maxWidth: "100%",
    },
  }));


export const Admin = (props) => {

    const classes = useStyles()

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [jwtScanned, setJwtScanned] = useState(false)

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const onClickLogin = async (event) => {
      event.preventDefault()
      const data = {
        "username": username,
        "password": password
      }
      const response = await apiPostRequest("/login", data)
      console.log(response)
      if(response[0] === 200){
        localStorage.setItem("jwt", response[1].token)
        setJwtScanned(false)
      }
    }

    const onClickLogout = (event) => {
      event.preventDefault()
      localStorage.removeItem("jwt")
      setTimeout(() => {
        setUsername("")
        setPassword("")
        setJwtScanned(false)
        setIsLoggedIn(false)        
      }, 250)      
    }

    useEffect(() => {
      if(!jwtScanned){
        const jwt = localStorage.getItem("jwt") 
        if(jwt){
          setIsLoggedIn(true)
        }
        setJwtScanned(true)
      }
    }, [isLoggedIn, jwtScanned])
    
    return (
      <>
      <div className={styles.top}>
        <div className={styles.container}>
          {!isLoggedIn && (
            <>
              <h1>login</h1>

              <form>
                <div>
                  <label>Username</label>
                  <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
                </div>
                <div>
                  <label>Password</label>
                  <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                </div>
                <button type="submit" onClick={onClickLogin}>Login</button>
              </form>
              <br/>
              <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
            </>
          )}
          {isLoggedIn && (
            <>
            <h1>Admin</h1>
            <button onClick={onClickLogout}>Logout</button>
            <p>The admin page contains forms that can change the content on the website.</p>


            <Paper className={classes.paper}>
                <Menus className={styles.menus} />
            </Paper>

            <Paper className={classes.paper}>
                <h1>Menu Button Previews</h1>
                <p>The buttons below are the same as the buttons that will be used by users to download the menus on other parts of the website.</p>

                <p>Clicking the buttons below will have the same effect as when clicked on the actual website.</p>
                
                
                <div className={styles.menuDownloadButtonsContainer}>
                    <MenuButton type="restaurant" environment={process.env.REACT_APP_ENVIRONMENT}></MenuButton>
                    <MenuButton type="drinks" environment={process.env.REACT_APP_ENVIRONMENT}></MenuButton>
                    <MenuButton type="whisky" environment={process.env.REACT_APP_ENVIRONMENT}></MenuButton>
                </div>

            </Paper>

            <Paper className={classes.paper}>
                <Banner />
            </Paper>

            <Paper className={classes.paper}>
                <h1>Banner Preview</h1>
                <p>The section below shows the banner exactly as it will appear on the website.</p>
                <p>Note, the banner can only appear on the website when it is enabled.</p>
                <BannerView environment={process.env.REACT_APP_ENVIRONMENT} force="true" />
            </Paper>


            <Paper className={classes.paper}>
                <OpeningTimes />
            </Paper>

            <Paper className={classes.paper}>
                <h1>Opening Times Preview</h1>
                <p>The section below shows a preview of how the opening times will display on the website.</p>
                <OpeningTimesView environment={process.env.REACT_APP_ENVIRONMENT} />
            </Paper>
                
            </>
          )}
         </div>
      </div>
      </>
    )
}